window.addEventListener("DOMContentLoaded", () => {
    // preloder
    try {
        if (document.body.contains(document.getElementById('preloader'))) {
            let images = document.images,
                imagesTotalCount = images.length,
                imagesLoadedCount = 0,
                preloaderPercents = document.getElementById("percents");

            for (let k = 0; k < imagesTotalCount; k++) {
                let imageClone = new Image();
                imageClone.onload = imageLoaded;
                imageClone.onerror = imageLoaded;
                imageClone.src = images[k].src;
            }

            function imageLoaded() {
                imagesLoadedCount++;
                preloaderPercents.innerHTML = `${((100 / imagesTotalCount) * imagesLoadedCount) << 0}%`;

                if (imagesLoadedCount >= imagesTotalCount) {
                    let preloader = document.getElementById("preloader");
                    setTimeout(() => {
                        if (!preloader.classList.contains("done")) {
                            preloader.classList.add("done");
                        }
                    }, 1000);
                }
            }
        }


    } catch (e) {
        console.log(e);
    }

    // burger
    try {

        if (window.innerWidth < 1024) {
            const burger = document.querySelectorAll("[data-burger]");
            const navsBottomMenu = document.querySelector(".navs-bottom-menu");
            const body = document.querySelector("body");

            burger.forEach((item) => {
                item.addEventListener("click", (e) => {
                    e.preventDefault();
                    burger.forEach((i) => {
                        i.classList.toggle("burger--active");
                    })
                    navsBottomMenu.classList.toggle("active");
                    body.classList.toggle("overflow-hidden");
                });
            });

            const navsMenuParentCat = document.querySelectorAll(".navs-menu-parent-cat");
            const navsMenuParentList = document.querySelectorAll(".navs-menu-parent-list");

            const dataMenuItem = document.querySelectorAll("[ data-menu-item]");

            navsMenuParentCat.forEach((item) => {
                item.addEventListener("click", (e) => {
                    item.classList.toggle("open");
                })
            });
            navsMenuParentList.forEach((item) => {
                item.addEventListener("click", (e) => {
                    item.classList.toggle("open");
                })
            });

            dataMenuItem.forEach((item) => {
                item.addEventListener("click", (e) => {
                    navsMenuParentCat.forEach((item) => {
                        item.classList.remove("open");
                    });
                    navsMenuParentList.forEach((item) => {
                        item.classList.remove("open");
                    });
                    burger.forEach((item) => {
                        item.addEventListener("click", (e) => {
                            burger.forEach((i) => {
                                i.classList.remove("burger--active");
                            })
                            navsBottomMenu.classList.remove("active");
                            body.classList.remove("overflow-hidden");
                        });
                    });
                })
            })
        }


    } catch (e) {
        console.log(e)
    }


    // добавление фона на фиксированное меню
    try {

        window.addEventListener('scroll', function () {
            let scrollTop = window.pageYOffset;

            if (scrollTop >= 100) {
                document.querySelector(".navs-bottom").classList.add("scroll");
                document.querySelector(".navs-top").style.opacity = "0";
                document.querySelector(".navs-search").classList.add("scroll");
            } else {
                document.querySelector(".navs-bottom").classList.remove("scroll");
                document.querySelector(".navs-top").style.opacity = "1";
                document.querySelector(".navs-search").classList.remove("scroll");
            }
        });

    } catch (e) {
        console.log(e)
    }

    // показать поиск
    try {
        const buttonSearchMobile = document.querySelector("#button-search-mobile");
        const navsMenuLiSearch = document.querySelector(".navs-menu-li-search");
        const navsSearch = document.querySelector(".navs-search");
        buttonSearchMobile.addEventListener("click", (e) => {
            e.preventDefault();
            navsSearch.classList.toggle("show");
        });
        navsMenuLiSearch.addEventListener("click", (e) => {
            e.preventDefault();
            navsSearch.classList.toggle("show");
        })
    } catch (e) {
        console.log(e)
    }

    // swiper-main
    try {
        const swiperMainCatalogue = new Swiper('.swiper-main-catalogue', {
            //loop: false,
            //speed: 400,
            spaceBetween: 16,
            slidesPerView: 1,
            pagination: {
                el: '.swiper-pagination',
            },
            navigation: {
                nextEl: '.swiper-button-next',
                prevEl: '.swiper-button-prev',
            },
            breakpoints: {
                768: {
                    slidesPerView: 2,
                    spaceBetween: 32
                },
                1200: {
                    slidesPerView: 3,
                }
            }
        });
    } catch (e) {
        console.log(e);
    }

    // swiper-content-video
    try {
        const swiperContentVideo = new Swiper('.swiper-content-video', {
            //loop: false,
            //speed: 400,
            spaceBetween: 16,
            slidesPerView: 1,
            pagination: {
                el: '.swiper-pagination',
            },
            navigation: {
                nextEl: '.swiper-button-next',
                prevEl: '.swiper-button-prev',
            },
            breakpoints: {
                768: {
                    slidesPerView: 2,
                    spaceBetween: 32
                },
                1200: {
                    slidesPerView: 3,
                }
            }
        });
    } catch (e) {
        console.log(e);
    }

    try {
        const swiperMainAdvantages = new Swiper('.swiper-main-advantages', {
            loop: true,
            speed: 800,
            autoplay: true,
            spaceBetween: 16,
            slidesPerView: 1,
            pagination: {
                el: '.swiper-pagination',
            },
            navigation: {
                nextEl: '.swiper-button-next',
                prevEl: '.swiper-button-prev',
            },
            breakpoints: {
                768: {
                    slidesPerView: 2,
                    spaceBetween: 32
                },
                1200: {
                    slidesPerView: 3,
                }
            }
        });
    } catch (e) {
        console.log(e);
    }


    try {
        const swiperContentReviews = new Swiper('.swiper-content-reviews', {
            //loop: false,
            //speed: 400,
            spaceBetween: 16,
            slidesPerView: 1,
            pagination: {
                el: '.swiper-pagination',
            },
            navigation: {
                nextEl: '.swiper-button-next',
                prevEl: '.swiper-button-prev',
            },
            breakpoints: {
                768: {
                    slidesPerView: 2,
                    spaceBetween: 32
                },
                1200: {
                    slidesPerView: 3,
                }
            }
        });
    } catch (e) {
        console.log(e);
    }


    try {
        const body = document.querySelector("body");
        const buttonsModal = document.querySelectorAll(".button-modal");


        buttonsModal.forEach((button) => {
            button.addEventListener("click", (e) => {
                e.preventDefault();

                //console.log(button.dataset.target);
                body.classList.add("overflow-hidden");
                document.querySelector(`#${button.dataset.target}`).classList.add("open");


                //console.log(button)

                if (button.dataset.videolink) {
                    document.querySelector(`#${button.dataset.target} .modal-form`).innerHTML = `<iframe loading="lazy" src="${button.dataset.videolink}" title="${button.dataset.videotitle}" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>`;
                }


            })
        });

        const modalClose = document.querySelectorAll(".modal-close");

        modalClose.forEach((item) => {
            item.addEventListener("click", (e) => {
                e.preventDefault();

                body.classList.remove("overflow-hidden");
                document.querySelector(`#${item.dataset.target}`).classList.remove("open");

                document.querySelector(`#${item.dataset.target} .modal-form`).innerHTML = "";

            })
        });


    } catch (e) {
        console.log(e);
    }

    // scroll to top
    try {
        const scrollToTop = document.querySelector("#scroll-to-top");
        scrollToTop.addEventListener("click", (e) => {
            e.preventDefault();
            window.scroll({
                top: 0,
                behavior: "smooth",
            })
        })

    } catch (e) {
        console.log(e)
    }

    // show/hide sidebar menu
    try {
        const showSidebarMenu = document.querySelector("#show-sidebar-menu");
        const body = document.querySelector("body");
        const containerSidebar = document.querySelector("#container-sidebar");
        const containerSidebarmenu = containerSidebar.querySelector(".container-sidebar-menu");
        showSidebarMenu.addEventListener("click", (e) => {
            e.preventDefault();
            showSidebarMenu.classList.toggle("open");
            showSidebarMenu.classList.contains("open") ? showSidebarMenu.innerText = "Закрыть каталог" : showSidebarMenu.innerText = "Открыть каталог";
            containerSidebar.classList.toggle("open");
            containerSidebarmenu.classList.toggle("open");
            body.classList.toggle("overflow-hidden");


        })
    } catch (e) {
        console.log(e);
    }

    try {
        const swiperApplicationOptions = new Swiper('.swiper-application-options', {
            loop: false,
            autoplay: false,
            spaceBetween: 16,
            slidesPerView: 1,
            pagination: {
                el: '.swiper-pagination',
            },
            navigation: {
                nextEl: '.swiper-button-next',
                prevEl: '.swiper-button-prev',
            },
            breakpoints: {
                768: {
                    slidesPerView: 2,
                    spaceBetween: 32
                },
                1200: {
                    slidesPerView: 4,
                }
            }
        });
    } catch (e) {
        console.log(e);
    }

});


// try{
//
//
// }catch (e) {
//     console.log(e)
// }

